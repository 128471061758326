import React, { useState, useContext, useEffect } from "react";

import {
  Avatar,
  Button,
  Datepicker,
  Clipboard,
  Checkbox,
  Divider,
  Dialog,
  EditIcon,
  Popup,
  Header,
  Input,
  Flex,
  Text,
  MenuButton,
  Loader,
  UserFriendsIcon,
  InfoIcon,
  Tooltip,
  TextArea,
  Status,
  Dropdown,
} from "@fluentui/react-northstar";

import {
  ArrowLeftIcon,
  AddIcon,
  CloseIcon,
  EmailIcon,
  ThumbtackIcon,
  TagIcon,
  CalendarIcon,
  SyncIcon,
  ClipboardCopiedToIcon,
} from "@fluentui/react-icons-northstar";

// Fetch all logic for Pinned Clause
import { PinnedClausesContext } from "./PinnedClausesBasket";

import { DocumentDisplayContext } from "./DocumentDisplayHandler";

import { fetchGraphQL } from "../data/graphql";

// json clauses data is used for Election Form
import { clauses } from "../data/clauses.js";
import { lps } from "../data/lps.js";

const debug = false;

function LibraryClauseLeftSideBar(props) {
  function SidebarWrapper(children) {
    const [tagType, setTagType] = useState(); // 0 = event 1 = date

    return (
      <Flex gap="gap.small" styles={{ maxWidth: "20%", minWidth: "20%" }}>
        <Flex column gap="gap.small" styles={{ width: "100%" }}>
          <Flex
            column
            padding="padding.medium"
            debug={debug}
            gap="gap.small"
            styles={{ width: "100%", backgroundColor: "#F9F5F4" }}
          >
            <Text temporary content="Three compliance tags:" size="smaller" />

            <Flex id="tags" column gap="gap.small">
              <Tooltip
                content={
                  <Flex column>
                    <Text
                      weight="bold"
                      content="Obligation to take a particular action at a particular date."
                    />
                  </Flex>
                }
                trigger={
                  <Button
                    fluid
                    icon={<CalendarIcon outline />}
                    iconPosition="after"
                    size="small"
                    content="01.02.2022"
                    secondary
                  />
                }
              />
              <Tooltip
                content={
                  <Flex column>
                    <Text
                      weight="bold"
                      content="A recurring obligation at e.g. a monthly interval."
                    />
                  </Flex>
                }
                trigger={
                  <Button
                    fluid
                    icon={<SyncIcon />}
                    iconPosition="after"
                    size="small"
                    content="Monthly"
                    secondary
                  />
                }
              />
              <Tooltip
                content={
                  <Flex column>
                    <Text
                      weight="bold"
                      content="Obligation owed in the event of a particular event."
                    />
                  </Flex>
                }
                trigger={
                  <Button
                    fluid
                    icon={<EmailIcon outline />}
                    iconPosition="after"
                    size="small"
                    content="Event"
                    secondary
                  />
                }
              />
            </Flex>

            <Flex id="Add tag" column gap="gap.smallest">
              <Divider />
              {/* TODO: Add modal triggered by menu selection */}

              <Dialog
                cancelButton="Cancel"
                confirmButton="Save"
                content={
                  <Flex gap="gap.small">
                    <Flex column styles={{ width: "49%" }} gap="gap.small">
                      <Flex vAlign="center" space="between">
                        <Text weight="bold" content="Select type" />
                        <Flex vAlign="center" gap="gap.small">
                          <Button
                            size="small"
                            content="Event"
                            primary={tagType ? true : false}
                            onClick={() => setTagType(true)}
                          />

                          <Button
                            size="small"
                            content="Time"
                            primary={!tagType ? true : false}
                            onClick={() => setTagType(false)}
                          />

                          <Tooltip
                            trigger={
                              <Button
                                iconOnly
                                text
                                icon={<InfoIcon outline size="medium" />}
                              />
                            }
                            content="Explanatory text for event types"
                          />
                        </Flex>
                      </Flex>

                      <Flex column gap="gap.small">
                        <Divider />
                        <Flex>
                          <Datepicker
                            disabled={tagType ? true : false}
                            inputPlaceholder="Select date..."
                            calendar={{
                              header: {
                                children: (ComponentType, props) => {
                                  return (
                                    <Flex space="between" hAlign="center">
                                      <Checkbox
                                        label="Recurring?"
                                        labelPosition="start"
                                      />
                                      <Datepicker.CalendarHeaderAction
                                        onClick={props.onPreviousClick}
                                        direction="previous"
                                        title={props.prevMonthAriaLabel}
                                        disabled={props.disabledPreviousButton}
                                      />
                                      <Text
                                        weight="bold"
                                        content={props.label}
                                        styles={{
                                          paddingTop: "5px",
                                        }}
                                      />
                                      <Datepicker.CalendarHeaderAction
                                        onClick={props.onNextClick}
                                        direction="next"
                                        title={props.nextMonthAriaLabel}
                                        disabled={props.disabledNextButton}
                                      />
                                    </Flex>
                                  );
                                },
                              },
                            }}
                          />
                        </Flex>
                        <Divider />
                      </Flex>

                      <Dropdown
                        fluid
                        checkable
                        placeholder="Select category"
                        items={["Example category", "Another example category"]}
                      />
                      <TextArea
                        resize="both"
                        placeholder="Enter text for operations manual"
                      />

                      <Divider />
                      <Button
                        icon={<AddIcon />}
                        disabled
                        content="Create additional tag"
                      />
                    </Flex>
                    <Flex space="between">
                      <Divider size={2} vertical />
                    </Flex>
                    <Flex column styles={{ width: "49%" }}>
                      {props.text}
                    </Flex>
                  </Flex>
                }
                header="Create compliance tag"
                headerAction={props.name}
                trigger={
                  <Button fluid icon={<AddIcon />} content="Add" size="small" />
                }
              />
            </Flex>
          </Flex>
        </Flex>
        <Divider vertical />
      </Flex>
    );
  }
  return SidebarWrapper();
}

{
  /* TODO: render sidebars based on DocumentDisplay status */
}

function LibraryClause(props) {
  const { DocumentDisplay } = useContext(DocumentDisplayContext);

  const pinnedClausesContext = useContext(PinnedClausesContext);

  const [pinnedState, setPinnedState] = useState(
    pinnedClausesContext.pinnedClauses.includes(props.id)
  );
  // this dynamically adjusts pin / unpin - maybe an easier way to do this:
  const dispatch = pinnedState ? "unpin" : "pin";

  console.log("metadata as read from clauses.js is: " + DocumentDisplay);

  // The clause list will necessitate custom components as the Northstar <List> does not linebreak
  const lpData = !DocumentDisplay.lpInfo ? null : (
    <>
      <Flex column>
        <Text weight="bold" size="small">
          LPs with clause in SL:
        </Text>

        <Flex wrap gap="gap.small" padding="padding.medium">
          {props.lps.map((x) => (
            <Tooltip
              trigger={
                <Button
                  icon={<Status state="warning" />}
                  content={x.investor.investor_name}
                  size="small"
                  text
                />
              }
              content="Click LP to see individual overview."
            />
          ))}
        </Flex>
      </Flex>

      <Flex column gap="gap.small">
        <Text weight="bold" size="small">
          LPs with clause in EF:
        </Text>
        <Flex
          wrap
          padding="padding.medium"
          hAlign="center"
          vAlign="center"
          styles={{ backgroundColor: "#E6E6E6" }}
        >
          <Text
            temporary
            size="small"
            content="Enable MFN to see LPs with clause in EF"
          />
        </Flex>
      </Flex>
    </>
  );

  const defaultCopyToClipboardMessage = "Click to Copy to Clipboard";
  const [copyToClipboardMessage, setCopyToClipboardMessage] = useState(
    defaultCopyToClipboardMessage
  );

  return (
    <Flex gap="gap.medium" debug={debug}>
      {DocumentDisplay.complianceInfo ? (
        <LibraryClauseLeftSideBar
          status={props.status}
          name={props.name}
          text={props.text}
        />
      ) : null}
      <Flex column gap="gap.small">
        <Flex vAlign="center" space="between">
          <Text
            content={props.name + " " + props.number + " " + props.id}
            weight="bold"
          />
          <Flex vAlign="center">
            <Tooltip
              content="Editing clauses is disabled"
              trigger={
                <Button
                  disabledFocusable
                  icon={<EditIcon outline />}
                  iconOnly
                  text
                />
              }
            />
            <Tooltip
              content={copyToClipboardMessage}
              trigger={
                <Button
                  icon={<ClipboardCopiedToIcon />}
                  iconOnly
                  text
                  size="medium"
                  onClick={() => {
                    navigator.clipboard.writeText(props.text);
                    setCopyToClipboardMessage("Clause copied to Clipboard");
                  }}
                  onMouseOut={() => {
                    setCopyToClipboardMessage(defaultCopyToClipboardMessage);
                  }}
                />
              }
            />
            <Button
              icon={pinnedState ? <ThumbtackIcon /> : <ThumbtackIcon />}
              size="medium"
              text={!pinnedState}
              iconOnly
              title="Pin"
              onClick={() => {
                pinnedClausesContext.pinnedClausesDispatch({
                  type: dispatch,
                  value: props.id,
                });
                setPinnedState(!pinnedState);
              }}
            />
          </Flex>
        </Flex>
        <Flex>
          <Text content={props.text} />
        </Flex>
        <Flex column>{lpData}</Flex>
      </Flex>
    </Flex>
  );
}

function ElectionFormLeftSideBar(props) {
  {
    /* key for status: 

    0 = INCLUDED = clause present in Election Form
    1 = MANUAL = manually excluded
    2 = AUTOMATIC NAME = automatically excluded due to name being excluded 
    3 = AUTOMATIC GROUP = automatically excluded due to group being excluded 
  
    SidebarWrapper is an embryo for a standardized sidebar layout - will not proceed further 

  */
  }

  function SidebarWrapper(children) {
    return (
      <Flex gap="gap.small" styles={{ maxWidth: "20%", minWidth: "20%" }}>
        <Flex column debug={debug} gap="gap.small" styles={{ width: "100%" }}>
          {children}
        </Flex>
        <Divider vertical />
      </Flex>
    );
  }

  switch (props.status) {
    case 0:
      return SidebarWrapper(
        <>
          <Flex column padding="padding.medium">
            <Text weight="bold" size="medium" content="Clause offered to LP" />
            <Divider />
          </Flex>
          <Button
            size="small"
            primary
            icon={<AddIcon />}
            content="Add to ISL"
          />
          <Button
            size="small"
            secondary
            icon={<CloseIcon />}
            content="Remove"
          />
        </>
      );
    case 1:
      return SidebarWrapper(
        <>
          <Flex column>
            <Text weight="bold" content="Manually excluded" />
            <Divider />
          </Flex>
          <Flex debug={debug} gap="gap.small" wrap>
            <Avatar size="small" name="Sven Svensson" />
            <Flex debug={debug} column>
              <Text size="small" content="Sven Svensson" weight="bold" />
              <Text
                size="small"
                content={new Date("01/01/2021").toDateString()}
              />
            </Flex>
          </Flex>

          <Flex debug={debug} column>
            <Text
              temporary
              size="small"
              content="I have excluded the clasue for important reasons"
            />
          </Flex>
          <Flex column gap="gap.small">
            <Button
              ciruclar
              size="small"
              primary
              icon={<AddIcon />}
              content="Add to ISL"
            />
            <Button
              size="small"
              secondary
              icon={<CloseIcon />}
              content="Exclude"
            />
          </Flex>
        </>
      );
    case 2:
      return SidebarWrapper(
        <>
          <Flex column>
            <Text weight="bold" content="Automatically excluded" />
            <Divider />
            <Text
              temporary
              size="small"
              content={"The clause name " + props.name + " has been excluded"}
            ></Text>
          </Flex>
          <Flex column gap="gap.small">
            <Button
              size="small"
              primary
              icon={<AddIcon />}
              content="Add to ISL"
            />
            <Button
              size="small"
              secondary
              icon={<CloseIcon />}
              content="View groups"
            />
          </Flex>
        </>
      );
    default:
      return SidebarWrapper(
        <>
          {" "}
          <Flex column>
            <Text weight="bold" size="medium" content="Clause offered to LP" />
            <Divider />
          </Flex>
          <Button
            size="small"
            primary
            icon={<AddIcon />}
            content="Add to ISL"
          />
          <Button
            size="small"
            secondary
            icon={<CloseIcon />}
            content="Exclude"
          />
        </>
      );
  }
}

function ElectionClause(props) {
  const { DocumentDisplay } = useContext(DocumentDisplayContext);

  // The clause list will necessitate custom components as the Northstar <List> does not linebreak
  const lpData = (
    <>
      <Text weight="bold" size="small">
        LPs with clause in SL:
      </Text>
      <Flex wrap gap="gap.small" padding="padding.medium">
        {lps.map((lp) => (
          <Button
            icon={<Status state="warning" />}
            content={lp.legalEntity}
            size="small"
            text
          />
        ))}
      </Flex>
      <Text weight="bold" size="small">
        LPs with clause in EF:
      </Text>
      <Flex wrap gap="gap.small" padding="padding.medium">
        {lps.map((lp) => (
          <Button
            icon={<Status state="warning" />}
            content={lp.legalEntity}
            size="small"
            text
          />
        ))}
      </Flex>
    </>
  );

  return (
    <Flex gap="gap.medium" debug={debug}>
      {props.sideBySide ? null : (
        <ElectionFormLeftSideBar status={props.status} name={props.name} />
      )}
      <Flex column gap="gap.small">
        <Flex column gap="gap.small">
          <Flex vAlign="center" debug={debug} gap="gap.small">
            {/* Replace below with function to cover all statuses */}
            <Status state={props.status === 0 ? "success" : "warning"} />
            <Text content={props.name + " " + props.number} weight="bold" />
            <Flex vAlign="center" gap="gap.medium">
              {props.sideBySide ? (
                <>
                  <Text temporary content="[M / A excluded]" />
                  <Tooltip
                    trigger={
                      <Button
                        circular
                        iconOnly
                        text
                        size="smaller"
                        icon={<InfoIcon outline />}
                      />
                    }
                    content="This will display details of rationale for exclusion."
                  />{" "}
                </>
              ) : null}
            </Flex>
          </Flex>
          <Flex vAlign="center" gap="gap.small">
            {!props.sideBySide ? null : (
              <>
                <Button icon={<ArrowLeftIcon />} content="Add" size="small" />
                <Button
                  icon={<CloseIcon />}
                  content="Remove / Re-add / View Groups"
                  size="small"
                />
              </>
            )}

            <Button size="small" text icon={<TagIcon />} content="group" />
          </Flex>
        </Flex>
        <Flex>
          <Text content={props.text} />
        </Flex>
        <Flex column>{DocumentDisplay.metaData ? lpData : null}</Flex>
      </Flex>
    </Flex>
  );
}

function DocumentClauseList({ nameFilter, searchInput, documentType }) {
  const [clausesList, setClauses] = useState();

  const operationsDoc = `
  query {
    clauses {
      id
      name
      number
      text
      investors_clauses {
        investor {
          investor_name
        }
      }
    }
  }  
  `;

  useEffect(() => {
    const fetchClauses = async () => {
      const { data, error } = await fetchGraphQL(operationsDoc, null, {});
      if (error) {
        return console.error(error);
      }
      setClauses(data.clauses);
    };
    fetchClauses();
  }, []);

  // Check if name filter applied
  const filteredNames = nameFilter
    ? clausesList.filter(({ name }) => nameFilter.includes(name))
    : clausesList;

  // Check if search results have been inputted
  const searchResults = searchInput
    ? filteredNames.filter(({ text }) =>
        text.toLowerCase().includes(searchInput.toLowerCase())
      )
    : filteredNames;

  return (
    <>
      <Text content={documentType} weight="bold" as="h3" />
      {searchResults ? (
        searchResults.map((clause) => (
          <LibraryClause
            id={clause.id}
            name={clause.name}
            number="x"
            text={clause.text}
            lps={clause.investors_clauses}
          />
        ))
      ) : (
        <Loader label="Loading clauses" />
      )}
    </>
  );
}

function ElectionClauseList({ nameFilter, searchInput, sideBySide }) {
  // Check if name filter applied
  const filteredNames = nameFilter
    ? clauses.filter(({ name }) => nameFilter.includes(name.label))
    : clauses;

  // Check if search results have been inputted
  const searchResults = searchInput
    ? filteredNames.filter(({ text }) =>
        text[0].toLowerCase().includes(searchInput.toLowerCase())
      )
    : filteredNames;

  return (
    <>
      <Text content="Election Form" weight="bold" as="h3" />
      {searchResults.map((clause) => (
        <ElectionClause
          id={clause.id}
          name={clause.name.label}
          number={clause.name.numOfName}
          text={clause.text}
          status={clause.status}
          sideBySide={sideBySide}
        />
      ))}
    </>
  );
}

export { DocumentClauseList, ElectionClauseList as IslClauseList };
