import React, { useState, useContext, useEffect } from "react";

import {
  Button,
  Card,
  Divider,
  Flex,
  Segment,
  Text,
} from "@fluentui/react-northstar";

import {
  AddIcon,
  ChevronStartIcon,
  ChevronEndIcon,
  ThumbtackIcon,
  WordColorIcon,
} from "@fluentui/react-icons-northstar";

import { clauses } from "../data/clauses.js";

export const PinnedClausesContext = React.createContext();

export function PinnedClauseReducer(state, action) {
  switch (action.type) {
    case "pin":
      // Using Set() to prevent duplicate additions
      const newExtendedState = Array.from(
        new Set([...state]).add(action.value)
      );
      console.log("pinned state: " + newExtendedState);
      console.log("is array:" + Array.isArray(newExtendedState));
      return newExtendedState;
    case "unpin":
      const clauseToUnpin = state.indexOf(action.value);
      const newDecreasedValue = [...state];
      console.log("action.value: " + action.value);
      console.log("state: " + state);
      console.log("indexOf clause to unpin: " + clauseToUnpin);
      newDecreasedValue.splice(clauseToUnpin, 1);
      console.log("unpin attempted");
      console.log("new state after unpin: " + newDecreasedValue);
      return newDecreasedValue;
    default:
      return console.log("the pinning action was not succesful");
  }
}

export function PinnedClauseBasket() {
  // TODO: Smooth out transition between expanded / minimized (by testing useTransition for animations?)
  // TODO: Break out mock clauses in basket in [] or similar
  // TODO: Make this a custom fluent component

  const [clausesList, setClauses] = useState(clauses);

  useEffect(() => {
    const fetchClauses = async () => {
      const result = await fetch(
        "https://bibliotek.hasura.app/api/rest/clauses",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret":
              "d4EIXj9cK4ILsqJZTyrt0IeUqNHbR6Z6TVPWYZXqLk2qa1JPFgPP3ECKj9yIqkAN",
          },
        }
      ).then((response) => response.json());
      setClauses(result.clauses);
    };
    fetchClauses();
  }, []);

  const pinnedClausesContext = useContext(PinnedClausesContext);

  const pinnedClausesList = clausesList.filter((clause) =>
    pinnedClausesContext.pinnedClauses.includes(clause.id)
  );

  const [basketExpanded, setBasketExpanded] = useState();
  const basketStatus = basketExpanded ? false : true;

  return (
    // Pinned clauses basket expanded
    <>
      <Flex
        gap="gap.small"
        column
        styles={{ position: "fixed", top: "30%", right: "1px", zIndex: "1" }}
      >
        <Segment styles={{ width: "100%" }} color="brand">
          {basketExpanded ? (
            <Flex column gap="gap.medium">
              <Flex vAlign="center" gap="gap.smaller">
                <Text size="medium" weight="bold">
                  Pinned clauses
                </Text>
                <Button
                  icon={<ChevronEndIcon />}
                  primary
                  circular
                  size="small"
                  onClick={() => setBasketExpanded(basketStatus)}
                />
              </Flex>
              <Flex column gap="gap.smaller">
                {pinnedClausesList.length === 0 ? (
                  <Card centered>
                    <Text weight="bold" content="No clauses pinned" />
                  </Card>
                ) : (
                  pinnedClausesList.map((clause) => {
                    return (
                      <Card key={clause.id}>
                        {" "}
                        <Text
                          content={clause.name + " " + clause.name.numOfName}
                        />{" "}
                      </Card>
                    );
                  })
                )}
              </Flex>
              <Flex column vAlign="end" gap="gap.small">
                <Divider />
                <Button
                  disabled={pinnedClausesList.length === 0 ? true : false}
                  size="small"
                  icon={<AddIcon />}
                  content="Assign to LP"
                  secondary
                />
                <Button
                  disabled={pinnedClausesList.length === 0 ? true : false}
                  size="small"
                  icon={<WordColorIcon />}
                  content="Export to Word"
                  primary
                />
              </Flex>
            </Flex>
          ) : (
            <Flex column gap="gap.medium">
              <Flex vAlign="center" gap="gap.smaller">
                <Button
                  icon={<ChevronStartIcon />}
                  primary
                  circular
                  size="small"
                  onClick={() => setBasketExpanded(basketStatus)}
                />
                {/* Because of all the state shenaningans , the pinnedClausesArray needs to be re-converted into an actual array */}
                <Button
                  icon={<ThumbtackIcon />}
                  size="small"
                  circular
                  content={
                    <Text weight="bold">
                      {pinnedClausesContext.pinnedClauses.length}
                    </Text>
                  }
                  secondary
                  styles={{ padding: "0.5em" }}
                />
              </Flex>
            </Flex>
          )}
        </Segment>
      </Flex>
    </>
  );
}
