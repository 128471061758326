import React from "react";

import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardBody,
  Divider,
  Text,
  Table,
  Tooltip,
  Flex,
  Segment,
  Status,
  Menu,
  Header,
  List,
  Image,
  Input,
} from "@fluentui/react-northstar";
import {
  CloseIcon,
  EditIcon,
  WordColorIcon,
  SearchIcon,
} from "@fluentui/react-icons-northstar";

import { Chart } from "@fluentui/react-charts";
import {
  StackedBarChart,
  DoughnutChart,
} from "@fluentui/react-charts/lib/lib/builder.js";

const datasets = [
  {
    label: "ERISA",
    data: [12, 20, 9, 3, 12],
    color: "#6264A7",
  },
  {
    label: "Insurance Company",
    data: [9, 12, 8, 3, 4],
    color: "#A6A7DC",
  },
  {
    label: "Private Pension Fund",
    data: [2, 1, 0, 0, 4],
    color: "#464775",
  },
  {
    label: "Public Pension Fund",
    data: [0, 1, 2, 3, 5],
    color: "#33344A",
  },
];

const circularDatasets = [
  {
    label: "Capital raised in relation to target",
    data: [6000, 4000, 0, 0, 0, 0],
    color: ["#C8C6C4", "#6264A7", "#BDBDE6", "#605E5C", "#464775", "#252423"],
  },
  {
    label: "Capital per formation process step",
    data: [0, 0, 3000, 2000, 4500, 500],
    color: ["#6264A7", "#C8C6C4", "#BDBDE6", "#605E5C", "#464775", "#252423"],
  },
];

const stackConfig = new StackedBarChart({
  areaLabel: "Horizontal bar chart sample",
  data: {
    labels: ["Subscription", "LPA", "ISL (draft)", "MFN", "Election completed"],
    datasets,
  },
});

const config = new DoughnutChart({
  areaLabel: "Horizontal bar chart sample",
  data: {
    labels: ["Remaining", "Current", "Subscription", "LPA", "ISL", "Election"],
    datasets: circularDatasets,
  },
});

const header = {
  key: "header",
  items: [
    {
      content: <Text content="Abbrv." weight="bold" />,
      key: "Abbrv.",
      styles: { justifyContent: "center" },
    },
    {
      content: <Text content="Name" weight="bold" />,
      key: "Name",
    },
    {
      content: <Text content="Type" weight="bold" />,
      key: "Type",
    },
    {
      content: <Text content="Country" weight="bold" />,
      key: "Country",
    },
    {
      content: <Text content="LPA" weight="bold" />,
      key: "Subscription",
    },
    {
      content: <Text content="Subscription" weight="bold" />,
      key: "LPA",
    },

    {
      content: <Text content="ISL" weight="bold" />,
      key: "ISL",
    },
    {
      content: <Text content="Election" weight="bold" />,
      key: "Election",
    },
    {
      content: null,
      key: "Actions",
    },
  ],
};

const rowsPlain = [
  {
    key: 1,
    items: [
      {
        content: "AP7",
        key: "1-1",
        styles: { justifyContent: "center", borderRight: "1px black solid" },
      },
      {
        content: (
          <Tooltip
            content="Allmänna Pensionsfonden"
            trigger={<Text content="Allmänna Pensionsfonden" />}
            position="before"
            align="center"
          />
        ),
        key: "1-2",
        truncateContent: true,
      },
      {
        content: "Pension Fund",
        key: "1-3",
      },
      {
        content: "Sweden",
        key: "1-4",
        id: "age-1",
      },
      {
        content: (
          <Flex vAlign="center" gap="gap.small">
            <Status state="success" />
            <Text content="Completed" />
          </Flex>
        ),
        key: "1-0",
      },
      {
        content: (
          <Flex vAlign="center" gap="gap.small">
            <Status state="warning" />
            <Text content="Pending review" />
          </Flex>
        ),
        key: "1-5",
      },
      {
        content: (
          <Flex vAlign="center" gap="gap.small">
            <Status state="info" />
            <Text content="In progress" />
          </Flex>
        ),
        key: "1-6",
      },
      {
        content: (
          <Flex vAlign="center" gap="gap.small">
            <Status state="unknown" />
            <Text content="Pending review" />
          </Flex>
        ),
        key: "1-7",
      },
      {
        content: (
          <Flex gap="gap.small" vAlign="center">
            <Button size="small" content="Contact info" disabled />
            {/* table layout not support now more content in the cell */}

            {/* <Button tabIndex={-1} icon="edit" circular text iconOnly title="edit tags" /> */}
          </Flex>
        ),
      },
    ],
  },
  {
    key: 2,
    items: [
      {
        content: "CPPIB",
        key: "1-1",
        styles: { justifyContent: "center", borderRight: "1px black solid" },
      },
      {
        content: (
          <Tooltip
            content="Canada Pension Plan Investment Board"
            trigger={<Text content="Canada Pension Plan Investment Board" />}
            position="before"
            align="center"
          />
        ),
        key: "1-2",
        truncateContent: true,
      },
      {
        content: "Pension Fund",
        key: "1-3",
      },
      {
        content: "Canada",
        key: "1-4",
        id: "age-1",
      },
      {
        content: (
          <Flex vAlign="center" gap="gap.small">
            <Status state="success" />
            <Text content="Completed" />
          </Flex>
        ),
        key: "1-0",
      },
      {
        content: (
          <Flex vAlign="center" gap="gap.small">
            <Status state="info" />
            <Text content="In progress" />
          </Flex>
        ),
        key: "1-5",
      },
      {
        content: (
          <Flex vAlign="center" gap="gap.small">
            <Status state="info" />
            <Text content="In progress" />
          </Flex>
        ),
        key: "1-6",
      },
      {
        content: (
          <Flex vAlign="center" gap="gap.small">
            <Status state="unknown" />
            <Text content="Pending review" />
          </Flex>
        ),
        key: "1-7",
      },
      {
        content: (
          <Flex gap="gap.small" vAlign="center">
            <Button size="small" content="Contact info" disabled />
            {/* table layout not support now more content in the cell */}

            {/* <Button tabIndex={-1} icon="edit" circular text iconOnly title="edit tags" /> */}
          </Flex>
        ),
      },
    ],
  },
];

const menuItems = [
  {
    key: "Overview",
    content: "Overview",
  },
  {
    key: "MFN",
    content: "MFN",
    disabled: true,
  },
  {
    key: "Election",
    content: "Election",
    disabled: true,
  },
];

function FIT() {
  return (
    <Flex column styles={{ width: "100%", backgroundColor: "#E6E6E6" }}>
      <Menu
        defaultActiveIndex={0}
        items={menuItems}
        pointing
        styles={{ borderRadius: "0", borderLeft: "0" }}
      />
      <Flex column>
        <Flex space="evenly" styles={{ margin: "2em" }}>
          <Flex
            column
            styles={{
              width: "100%",
              maxWidth: "700px",
              minHeight: "400px",
              background: "white",
              borderRadius: "4px",
              padding: "24px",
              boxShadow:
                "rgba(17, 12, 46, 0.075) 0px 48px 100px 0px, rgba(0, 0, 0, 0.075) 0px 60px 20px -60px",
            }}
          >
            <Text
              content="Status in formation process by Investor category"
              weight="bold"
              styles={{ paddingBottom: "1em" }}
            />
            <Chart {...stackConfig} />
          </Flex>
          <Flex
            column
            styles={{
              width: "100%",
              maxWidth: "500px",
              minHeight: "400px",
              background: "white",
              borderRadius: "4px",
              padding: "24px",
              boxShadow:
                "rgba(17, 12, 46, 0.075) 0px 48px 100px 0px, rgba(0, 0, 0, 0.075) 0px 60px 20px -60px",
            }}
          >
            <Text
              content="Capital raised thus far (millions) [annotations WIP]"
              weight="bold"
              styles={{ paddingBottom: "1em" }}
            />
            <Chart {...config} />
          </Flex>
        </Flex>

        <Flex column styles={{ backgroundColor: "#FFFFFF", margin: "2em" }}>
          <Flex vAlign="center" gap="gap.medium">
            <Text
              content="List of Investors"
              weight="bold"
              styles={{ margin: "2em 0 1em 4em" }}
            />
            <Flex styles={{ width: "80%", margin: "2em 0 1em 4em" }}>
              <Input
                icon={<SearchIcon />}
                placeholder="Search for Investor"
                fluid
              />
            </Flex>
          </Flex>
          <Table
            variables={{
              cellContentOverflow: "none",
            }}
            header={header}
            rows={rowsPlain}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default FIT;
