import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { getTheme, initializeIcons } from "@fluentui/react";
import { Sidebar } from "@fluentui/react-experiments";
import { Flex } from "@fluentui/react-northstar";

import Library from "./Pages/Library.js";
import Investors from "./Pages/Investors.js";
import User from "./Pages/User.js";
import FIT from "./Pages/FIT.js";

// TODO: Register custom Icons to swap to Northstar rather than Fluent 8 icons
initializeIcons();

function App() {
  let navigate = useNavigate();

  let location = useLocation();

  const menuItems = [
    {
      key: "FIT",
      name: "FIT",
      iconProps: { iconName: "FinancialSolid" },
      active: location.pathname === "/" ? true : false,
      onClick: () => navigate("/"),
    },
    {
      key: "library",
      name: "Clause Library",
      iconProps: { iconName: "Library" },
      active: location.pathname === "/library" ? true : false,
      onClick: () => navigate("/library"),
    },
    {
      key: "lp",
      name: "Side Letters",
      iconProps: { iconName: "Bullseye" },
      disabled: false,
      active: location.pathname === "/investors" ? true : false,
      onClick: () => navigate("/investors"),
    },
    {
      key: "register",
      name: "LP register",
      iconProps: { iconName: "CompanyDirectory" },
      disabled: true,
    },
    {
      key: "mfn",
      name: "MFN",
      iconProps: { iconName: "BranchCompare" },
      disabled: true,
    },
  ];

  return (
    <Flex>
      <Flex column styles={{ position: "sticky", top: 0, height: "100vh" }}>
        <Sidebar
          styles={{ border: "1px" }}
          colors={1}
          collapsible={true}
          items={menuItems}
          footerItems={[
            {
              key: "basic-collapsible-footer-settings",
              name: "Settings",
              iconProps: { iconName: "Settings" },
              active: location.pathname === "/settings" ? true : false,
              onClick: () => navigate("/settings"),
              disabled: true,
            },
            {
              key: "basic-collapsible-footer-item",
              name: "{User}",
              iconProps: { iconName: "UserOptional" },
              active: location.pathname === "/user" ? true : false,
              items: [
                {
                  key: "collapsible-footer-item-link2",
                  name: "Activity",
                  iconProps: { iconName: "Feedback" },
                  onClick: () => navigate("/user"),
                },
                {
                  key: "collapsible-footer-item-link2",
                  name: "Sign-out",
                  iconProps: { iconName: "Leave" },
                },
              ],
            },
          ]}
          theme={getTheme()}
          styles={{ root: { maxWidth: "170px" } }}
        />
      </Flex>

      <Routes>
        <Route path="/" element={<FIT />} />
        <Route path="/library" element={<Library />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/user" element={<User />} />
      </Routes>
    </Flex>
  );
}

export default App;
