import React from "react";

import {
  Avatar,
  Card,
  CardHeader,
  CardBody,
  Divider,
  Text,
  Flex,
  Segment,
  Menu,
  Header,
  List,
  Image,
} from "@fluentui/react-northstar";
import {
  CloseIcon,
  EditIcon,
  WordColorIcon,
} from "@fluentui/react-icons-northstar";

const items = [
  {
    key: "0",
    media: <WordColorIcon size="large" />,
    header: "Withdrawal clause added to Limited Partner",
    headerMedia: "7:26:56 AM",
  },
  {
    key: "1",
    media: <WordColorIcon size="large" />,
    header: "Withdrawal clause added to Limited Partner A",
    headerMedia: "7:26:56 AM",
  },
  {
    key: "2",
    media: <CloseIcon size="medium" />,
    header: "MFN removed from Limited Partner B",
    content: "14 March 2020",
  },
  {
    key: "3",
    media: <EditIcon size="medium" outline />,
    header: "Advisory IV edited",
    content: "17 March 2020",
  },
];

const menuItems = [
  {
    key: "Activity",
    content: "Activity",
  },
  {
    key: "Settings",
    content: "Settings",
    disabled: true,
  },
];

function User() {
  return (
    <Flex column styles={{ width: "100%", backgroundColor: "#E6E6E6" }}>
      <Menu
        fluid
        items={menuItems}
        pointing
        styles={{ borderRadius: "0", borderLeft: "0" }}
      />
      <Flex fill>
        <Flex column styles={{ width: "50%" }} fill>
          <Segment styles={{ padding: 0, width: "100%", height: "100%" }}>
            <Flex hAlign="center" padding="padding.medium">
              <Text
                weight="bold"
                color="brand"
                content="Activity log for {user}"
              />
            </Flex>
            <Divider fitted />
            <List selectable defaultSelectedIndex={0} items={items} />
          </Segment>
        </Flex>

        <Flex fill vAlign="center" hAlign="center">
          <Text
            weight="bold"
            content="Select activity item in the list to your left to show details"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default User;
