import React, { useState, useContext } from "react";

import { Checkbox, SplitButton } from "@fluentui/react-northstar";

import {
  CheckmarkCircleIcon,
  CircleIcon,
} from "@fluentui/react-icons-northstar";

export const initialDocumentMetadata = {
  metaData: false,
  lpInfo: false,
  complianceInfo: false,

  sideBySide: true,
};

export const DocumentDisplayContext = React.createContext();

export function DocumentDisplayReducer(state, action) {
  {
    /* TODO: make reducer actions take better account of current state  */
  }

  switch (action.type) {
    case "metaData":
      return {
        ...state,
        metaData: !state.metaData,
        lpInfo: !state.lpInfo,
        complianceInfo: !state.complianceInfo,
      };
    case "lpInfo":
      return {
        ...state,
        lpInfo: !state.lpInfo,
      };
    case "complianceInfo":
      return {
        ...state,
        complianceInfo: !state.complianceInfo,
      };

    case "sideBySide":
      return {
        ...state,
        sideBySide: !state.sideBySide,
      };

    default:
      return console.log("broken");
  }
}

export function MetadataToggle() {
  const { DocumentDisplay, DocumentDisplayHandlerDispatch } = useContext(
    DocumentDisplayContext
  );

  {
    /* TODO: Turn into switch statement with one state */
  }

  return (
    <SplitButton
      menu={[
        {
          key: "lps",
          content: (
            <Checkbox
              label="Show LP information"
              checked={DocumentDisplay.lpInfo}
            />
          ),
          onClick: () => DocumentDisplayHandlerDispatch({ type: "lpInfo" }),
        },
        {
          key: "compliance",
          content: (
            <Checkbox
              label="Show Compliance Tags"
              checked={DocumentDisplay.complianceInfo}
            />
          ),
          onClick: () =>
            DocumentDisplayHandlerDispatch({ type: "complianceInfo" }),
        },
      ]}
      button={{
        content: "Metadata",
        icon: DocumentDisplay.metaData ? (
          <CheckmarkCircleIcon outline size="large" />
        ) : (
          <CircleIcon outline size="large" />
        ),
      }}
      align="end"
      onMainButtonClick={() =>
        DocumentDisplayHandlerDispatch({ type: "metaData" })
      }
    />
  );
}
