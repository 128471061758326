import React, { useEffect, useState, useReducer } from "react";

import {
  Card,
  Button,
  Checkbox,
  Dropdown,
  Divider,
  Dialog,
  Flex,
  Input,
  Segment,
  SplitButton,
  Portal,
  List,
  Text,
} from "@fluentui/react-northstar";

import {
  SearchIcon,
  WordColorIcon,
  CheckmarkCircleIcon,
  CircleIcon,
  TranscriptIcon,
} from "@fluentui/react-icons-northstar";

// Fetch all logic for Pinned Clause
import {
  PinnedClauseReducer,
  PinnedClausesContext,
  PinnedClauseBasket,
} from "../Components/PinnedClausesBasket";

import {
  initialDocumentMetadata,
  DocumentDisplayReducer,
  DocumentDisplayContext,
  MetadataToggle,
} from "../Components/DocumentDisplayHandler";

import { DocumentClauseList } from "../Components/Clauses.js";

function Library() {
  const [pinnedClauses, dispatch] = useReducer(PinnedClauseReducer, []);

  const [DocumentDisplay, DocumentDisplayHandlerDispatch] = useReducer(
    DocumentDisplayReducer,
    initialDocumentMetadata
  );

  const [searchText, setSearchText] = useState();
  const [nameFilter, setNameFilter] = useState();

  const [clauseNames, setClauseNames] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(
        "https://bibliotek.hasura.app/api/rest/clause_names",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-hasura-admin-secret":
              "d4EIXj9cK4ILsqJZTyrt0IeUqNHbR6Z6TVPWYZXqLk2qa1JPFgPP3ECKj9yIqkAN",
          },
        }
      ).then((response) => response.json());
      setClauseNames(result.clause_names.map((x) => x.clause_name));
    };
    fetchData();
  }, []);

  console.log("readeMode on render is: " + DocumentDisplay);

  return (
    <PinnedClausesContext.Provider
      value={{ pinnedClauses, pinnedClausesDispatch: dispatch }}
    >
      <DocumentDisplayContext.Provider
        value={{ DocumentDisplay, DocumentDisplayHandlerDispatch }}
      >
        <Flex column styles={{ width: "100%" }}>
          <Flex
            styles={{
              height: "4em",
              padding: "0 4em",
              position: "sticky",
              top: "0",
              backgroundColor: "white",
              zIndex: 1000,
            }}
            hAlign="center"
            vAlign="center"
            gap="gap.smaller"
          >
            <Dropdown
              multiple
              checkable
              items={clauseNames}
              placeholder="Filter Clause Names"
              // Check length of array to prevent dropdown cleared returning no clauses
              onChange={(_, { value }) =>
                setNameFilter(value.length === 0 ? null : value)
              }
            />

            <Input
              icon={<SearchIcon />}
              placeholder="Search"
              fluid
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Flex gap="gap.small">
              <MetadataToggle />
              <Dialog
                backdrop={true}
                cancelButton="Cancel"
                confirmButton="Export"
                onConfirm={() => alert("download")}
                header={
                  <Text
                    size="large"
                    weight="semibold"
                    content="Select the clause names you would like to export to Word"
                  />
                }
                content={
                  <Flex gap="gap.small">
                    <Flex column wrap styles={{ width: "100%" }}>
                      <Divider />
                      <Checkbox
                        disabled
                        label={<Text weight="bold" content="Complete MSL" />}
                      />
                      {clauseNames
                        ? clauseNames.map((name) => <Checkbox label={name} />)
                        : "Loading"}
                    </Flex>
                  </Flex>
                }
                trigger={
                  <Button
                    icon={<WordColorIcon />}
                    content="Export MSL"
                    secondary
                  />
                }
              />
            </Flex>
          </Flex>

          <PinnedClauseBasket />

          <Flex
            hAlign="center"
            styles={{ backgroundColor: "#E6E6E6", padding: "4em 18em" }}
            gap="gap.large"
            fill
          >
            <Segment color="red">
              <Flex
                column
                gap="gap.large"
                styles={
                  DocumentDisplay.metaData
                    ? { padding: "1em 4em 4em 1em" }
                    : { padding: "1em 4em 4em 4em" }
                }
              >
                <DocumentClauseList
                  documentType="Clause Library for Fund X"
                  searchInput={searchText}
                  nameFilter={nameFilter}
                />
              </Flex>
            </Segment>
          </Flex>
        </Flex>
      </DocumentDisplayContext.Provider>
    </PinnedClausesContext.Provider>
  );
}

export default Library;
