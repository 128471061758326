import React, { useContext, Suspense, useState, useReducer } from "react";

import {
  Button,
  Checkbox,
  Dropdown,
  Divider,
  Flex,
  Input,
  Segment,
  SplitButton,
  Portal,
  Menu,
  Text,
  List,
} from "@fluentui/react-northstar";

import {
  CheckmarkCircleIcon,
  CircleIcon,
  ExpandIcon,
  SearchIcon,
  WordColorIcon,
  TranscriptIcon,
} from "@fluentui/react-icons-northstar";

// Fetch all logic for Pinned Clause
import {
  PinnedClauseReducer,
  PinnedClausesContext,
  PinnedClauseBasket,
} from "../Components/PinnedClausesBasket";

import {
  initialDocumentMetadata,
  DocumentDisplayReducer,
  DocumentDisplayContext,
  MetadataToggle,
} from "../Components/DocumentDisplayHandler";

import { DocumentClauseList, IslClauseList } from "../Components/Clauses.js";

import { clauses } from "../data/clauses.js";
import { lps } from "../data/lps.js";

const items = [
  {
    key: "0",
    header: "AISIN Employees' Pension Fund",
  },
  {
    key: "1",
    header: "ALVOSO LLB Pensionskasse",
  },
  {
    key: "2",
    header: "Xcel Energy, Inc",
  },
  {
    key: "3",
    header: "Abu Dhabi Retirement Pensions and Benefits Fund",
  },
];

// Create unique list of clause names, filtered by using Set()
const clauseNames = Array.from(
  new Set(
    clauses.map((clause) => {
      return clause.name.label;
    })
  )
);

function Investors() {
  const [pinnedClauses, pinnedClausesDispatch] = useReducer(
    PinnedClauseReducer,
    []
  );

  const [DocumentDisplay, DocumentDisplayHandlerDispatch] = useReducer(
    DocumentDisplayReducer,
    initialDocumentMetadata
  );

  const [sideBySide, setSideBySide] = useState(false);

  const [searchText, setSearchText] = useState();
  const [nameFilter, setNameFilter] = useState();

  return (
    <PinnedClausesContext.Provider
      value={{ pinnedClauses, pinnedClausesDispatch }}
    >
      <DocumentDisplayContext.Provider
        value={{ DocumentDisplay, DocumentDisplayHandlerDispatch }}
      >
        {sideBySide ? null : (
          <Flex
            column
            styles={{
              position: "sticky",
              top: "0",
              height: "100%",
            }}
          >
            <Flex
              styles={{
                height: "3.9em", // Investigate why 4 is too high later
                padding: "0",
                backgroundColor: "#FFFFFF",
                borderRight: "1px #E6E6E6 solid",
              }}
              hAlign="center"
              vAlign="center"
            >
              <b>Investors</b>
            </Flex>
            <List selectable defaultSelectedIndex={0} items={items} />{" "}
          </Flex>
        )}

        <Flex column styles={{ width: "100%" }}>
          <Flex
            styles={{
              height: "4em",
              padding: "0",
              position: "sticky",
              top: "0",
              backgroundColor: "#FFFFFF",
              zIndex: 1000,
            }}
            vAlign="center"
          >
            <Flex
              fill
              hAlign="center"
              vAlign="center"
              space="between"
              gap="gap.smaller"
              styles={{ padding: "0 4em" }}
            >
              <Button
                icon={<ExpandIcon />}
                onClick={() => setSideBySide(!sideBySide)}
                primary={sideBySide ? true : false}
                circular
              />
              <Dropdown
                multiple
                checkable
                items={clauseNames}
                placeholder="Filter Clause Names"
                // Check length of array to prevent dropdown cleared returning no clauses
                onChange={(_, { value }) =>
                  setNameFilter(value.length === 0 ? null : value)
                }
              />

              <Input
                icon={<SearchIcon />}
                placeholder="Search"
                fluid
                onChange={(e) => setSearchText(e.target.value)}
              />

              <Flex gap="gap.small">
                <MetadataToggle />
                {/* TODO: Adjust absolute position - currently breaks on scroll */}
                <Button icon={<WordColorIcon />} content="Export ISL and EF" />
              </Flex>
            </Flex>
          </Flex>
          <PinnedClauseBasket />
          {/* TODO: Change ternary to switch or similar to adjust padding conditional on readerMode too*/}
          <Flex
            styles={
              sideBySide
                ? { backgroundColor: "#E6E6E6", padding: "4em 4em" }
                : { backgroundColor: "#E6E6E6", padding: "4em 4em" }
            }
            column={!sideBySide}
            gap="gap.large"
            hAlign="center"
            fill
          >
            <Flex styles={sideBySide ? { width: "50%" } : null}>
              <Segment color="red">
                <Flex
                  column
                  gap="gap.large"
                  styles={
                    DocumentDisplay.metaData
                      ? { padding: "1em 4em 4em 1em" }
                      : { padding: "1em 4em 4em 4em" }
                  }
                >
                  <DocumentClauseList
                    documentType="ISL for Investor XYZ"
                    searchInput={searchText}
                    nameFilter={nameFilter}
                  />
                </Flex>
              </Segment>
            </Flex>

            <Flex styles={sideBySide ? { width: "50%" } : null}>
              <Segment color="brand">
                <Flex
                  column
                  gap="gap.large"
                  styles={
                    sideBySide
                      ? { padding: "1em 4em 4em 4em" }
                      : { padding: "1em 4em 4em 1em" }
                  }
                >
                  <IslClauseList
                    searchInput={searchText}
                    nameFilter={nameFilter}
                    sideBySide={sideBySide}
                  />
                </Flex>
              </Segment>
            </Flex>
          </Flex>
        </Flex>
      </DocumentDisplayContext.Provider>
    </PinnedClausesContext.Provider>
  );
}

export default Investors;
