export const lps = [
  {
    legalEntity: "AFP Cuprum S.A.",
    location: "Santiago, Chile",
    cats: {
      label: "Public Pension Fund",
    },
  },
  {
    legalEntity: "AIB Group Irish Pension Scheme",
    location: "Dublin, Ireland",
    cats: {
      label: "Private Pension Fund",
    },
  },
  {
    legalEntity: "AISIN Employees' Pension Fund",
    location: "Aichi, Japan",
    cats: {
      label: "Private Pension Fund",
    },
  },
  {
    legalEntity: "ALVOSO LLB Pensionskasse",
    location: "Lachen, Switzerland",
    cats: {
      label: "Public Pension Fund",
    },
  },
  {
    legalEntity: "AP Fonden 1",
    location: "Stockholm, Sweden",
    cats: {
      label: "Public Pension Fund",
    },
  },
  {
    legalEntity: "Xcel Energy, Inc",
    location: "Minneapolis, United States",
    cats: {
      label: "Private Pension Fund",
    },
  },
  {
    legalEntity: "Worchestershire County Council Pension Fund",
    location: "Worchester, United Kingdom",
    cats: {
      label: "Public Pension Fund",
    },
  },
  {
    legalEntity: "Windstream Corporation",
    location: "Little Rock, United States",
    cats: {
      label: "Private Pension Fund",
    },
  },
  {
    legalEntity: "Wiltshire Pension Fund",
    location: "Trowbridge, United Kingdom",
    cats: {
      label: "Public Pension Fund",
    },
  },
  {
    legalEntity: "Willis Towers Watson",
    location: "London, United Kingdom",
    cats: {
      label: "Private Pension Fund",
    },
  },
  {
    legalEntity: "Gastrosocial Pensionskasse",
    location: "Aarau, Switzerland",
    cats: {
      label: "Insurance Company",
    },
  },
  {
    legalEntity: "Abu Dhabi Retirement Pensions and Benefits Fund",
    location: "Abu Dhabi, United Arab Emirates",
    cats: {
      label: "Public Pension Fund",
    },
  },
  {
    legalEntity: "National Pension Commission Pension Fund",
    location: "Abuja, Nigeria",
    cats: {
      label: "Public Pension Fund",
    },
  },
  {
    legalEntity: "Nation Media Group",
    location: "Nairobi, Kenya",
    cats: {
      label: "Private Pension Fund",
    },
  },
  {
    legalEntity: "RSA Pensions Manchester",
    location: "Manchester, United Kingdom",
    cats: {
      label: "Asset Manager",
    },
  },
  {
    legalEntity: "Government Pension Investment Fund",
    location: "Tokyo, Japan",
    cats: {
      label: "Public Pension Fund",
    },
  },
  {
    legalEntity: "Dalhousie University Pension Trust Fund",
    location: "Halifax, Canada",
    cats: {
      label: "Private Pension Fund",
    },
  },
];
