// status:
// 0: can be removed
// 1: manually excluded
// 2: automatically excluded
// 3: can't be removed from EF without adjusting groups

export const clauses = [
  {
    id: 1,
    status: 0,
    text: [
      `During the period from the date of this [Agreement] through the [Fund Expiration Date/Investment Period Closing Date], neither the [Partnership] nor its [Manager] shall enter into additional agreements with any existing or future [investor]s in the [[Partnership]] that have the effect of establishing rights or otherwise benefiting such [investor] in a manner more favorable in any material respect to such [investor] than the rights and benefits established in favor of the [Investor] by this [Agreement], unless, in any such case, the Purchasers have been provided with such rights and benefits`,
    ],
    name: {
      label: "Most Favoured Nation",
      numOfName: 1,
    },
  },
  {
    id: 2,
    status: 1,
    text: [
      'Neither the Partnership, the Investment Manager nor any of their respective affiliates has or shall enter into any side letter, subscription, investment advisory or similar agreement or arrangement with an existing or future investor [including an investor in a separate series or class of Shares in the Partnership] investing an amount equal to or less than the amount invested by the Investor in the Partnership after the date hereof that has the effect of establishing rights or benefits to such investor that are more favorable in any material respect than the rights or benefits granted to the Investor pursuant to this Agreement, unless the Investor has been offered the opportunity to receive the same rights to the extent they reasonably relate to the interests of the Investor [provided that Investor shall be required to agree to any less favorable provisions agreed to by an investor in connection with the receipt of a more favorable provision] (the "MFN Offer"). In connection with any such MFN Offer, the Investment Manager or its affiliate shall provide a copy of the relevant side letters or other relevant documents or a compendium containing the provisions of any such side letters or other relevant documents to the Investor. The Investor shall have 30 days from the receipt of the MFN Offer to respond, and if the Investor elects to accept such additional rights and benefits, such rights and benefits shall be effective as to all Shares of the Partnership purchased by the Investor.',
    ],
    name: {
      label: "Most Favoured Nation",
      numOfName: 2,
    },
  },
  {
    id: 3,
    status: 3,
    text: [
      `Prior to the [Partnership]'s investment of the [Partnership Funds] into a [Portfolio Company], the [Manager] will provide the [Investor] with at least [NOTICE PERIOD] prior written notice of such investment, including the price and terms thereof. The [Investor] will have the right, but not the obligation, to invest an amount (in the aggregate across all such offerings) up to but not greater than [MULTIPLE] the amount of the [CONTRIBUTION PERCENTAGE] held by the [Investor] at the time of such investment. Such investments by the [Investor] will be on the same terms and at the same price as the [Partnership] offers to the other [investor]s. The [Partnership] will have no obligation to offer this investment opportunity to the [Investor] under this [SECTION NUMBER] in [Portfolio Investment] if (a) at the time of such offering, the [Investor] is not an " accredited [investor] " as that term is then defined in [Rule 501(a) of Regulation D of the Securities Act] and (b) such offering of [Equity Securities] is otherwise being offered only to [RESTRICTIONS].`,
    ],
    name: {
      label: "Co-Investment Participation",
      numOfName: 3,
    },
  },
  {
    id: 4,
    status: 0,
    text: [
      "If at any time the General Partner determines, after consultation with the affected Limited Partner and after receipt of an opinion of counsel, which counsel and opinion shall be reasonably satisfactory to such affected Limited Partner, that there is a reasonable likelihood that the continuing participation in the Fund by such Limited Partner would cause a Material Adverse Effect, such Limited Partner shall, upon the written request and with the reasonable cooperation of the General Partner, use commercially reasonable efforts to dispose of such Limited Partner’s entire interest in the Fund (or such portion of its interest as the General Partner shall determine is sufficient to prevent or remedy such Material Adverse Effect) to one or more of the other Limited Partners or any other Person at a price reasonably acceptable to such Limited Partner, in a transaction that complies with [SECTION] and the General Partner shall use its commercially reasonable efforts to work with such Limited Partner to facilitate such transaction. If a determination is made by the General Partner under this [SECTION NO] that would affect more than one Limited Partner in substantially the same manner, the General Partner shall request that all of the affected Limited Partners take the actions set forth in the preceding sentence",
    ],
    name: {
      label: "Withdrawals",
      numOfName: 4,
    },
  },
  {
    id: 5,
    status: 2,
    text: [
      "Notwithstanding anything in the Partnership Documents to the contrary, [including [RELEVANT SECTION NUMBER] of the Limited Partnership Agreement], the Investment Manager will provide the Investor with ninety (90) days' notice prior to any involuntary withdrawal, provided, however, that no notice shall be required with respect to the Investor (i) if the Investment Manager has reason to believe that Investor acquired an interest in the Partnership as the result of a material misrepresentation, or (ii) if the Investment Manager has reasonably concluded, after consulting with counsel, that the Investor's ownership of an interest would cause the Partnership to be in violation of any law or regulation applicable to the Partnership; provided further that for purposes of the foregoing sub-clause (ii), the Partnership’s holding plan assets of any benefit plan investor shall not be deemed to violate any law or regulation",
    ],
    name: {
      label: "Withdrawals",
      numOfName: 2,
    },
  },
  {
    id: 6,
    status: 3,
    text: [
      `[Manager] will cause the [Partnership] to comply with the requirements of [Section 404(b)] of [ERISA (relating to maintaining indicia of ownership of plan assets in the United States] and the regulations thereunder. The [Partnership] shall maintain at least [NUMBER] of its portfolio investments in the United States.`,
    ],
    name: {
      label: "ERISA",
      numOfName: 5,
    },
  },
  {
    id: 7,
    status: 0,
    text: [
      `[Management Fees] allocated to the [Investor] shall be offset by the portion of (a) [Transaction Fees], (b) [Portfolio Monitoring Fees], and (c) [Director/Advisory Fees] allocated to the [Investor]; provided that any offset shall not include any transaction fees allocated to any [co-investors/management fees] charged to co-investors. [Management Fees] shall also be offset by [NUMBER] of any transaction fees allocated to or management fees charged to any co-investor, not to exceed [THRESHOLD-NUMBER]`,
    ],
    name: {
      label: "Offset of Fees and Expenses",
      numOfName: 1,
    },
  },
  {
    id: 8,
    status: 2,
    text: [
      `[Manager] will deliver to the [Investor]: (a) on an annual basis within [DAYS] of the close of the [Fiscal Year] (i) audited financial statements prepared in accordance with [US GAAP], (ii) summary descriptions of the portfolio investments, including any related material events or dispositions that occurred during that annual period, and (iii) tax information pertaining to each limited partner that allows the limited partner to prepare its income tax returns (including Schedule K-1); (b) on a quarterly basis within [DAYS] of the close of such [Fiscal Quarter] (i) unaudited financial statements and (ii) summary descriptions of the portfolio investments, including any related material events or dispositions that occurred during that quarterly period; and (c) such information relating to the financial condition, business or corporate affairs of the Partnership as the [Investor] may from time to time reasonably request for purposes of regulatory compliance. Notwithstanding anything herein to the contrary, the [Fund] will not be obligated to provide information (i) that it deems in good faith to be confidential information or (ii) the disclosure of which would adversely affect the attorney-client privilege between the [Fund] and its counsel`,
    ],
    name: {
      label: "Information Rights",
      numOfName: 2,
    },
  },
  {
    id: 9,
    status: 3,
    text: [
      `[Manager] will invite a representative of the [Investor] (a "[Committee Member]") to attend all meetings of the [Fund]'s [Advisory Board] and will give such [Committee Member] copies of all notices, minutes, consents and other materials that it provides to its directors; provided, however, that such [Committee Member] will agree to hold in confidence and trust all information so provided. Any [Committee Member] will be required to enter into a confidentiality agreement containing terms substantially similar to those set forth in [Section] of this [Agreement] with the [Fund] prior to the exercise of the rights contained in [Section]`,
    ],
    name: {
      label: "Advisory Committee",
      numOfName: 3,
    },
  },
  {
    id: 1,
    status: 1,
    text: [
      `[Investor] agrees to use the same degree of care as it uses to protect its own confidential information for any information obtained by the [Investor] pursuant to this [Agreement] and agrees that it will comply with the confidentiality provisions in [Section] of the [LPA]; provided that [Investor] may disclose such proprietary or confidential information as required by [SPECIFIC STATUTE, REGULATION, OR LAW] law, rule, regulation or court or other governmental order.`,
    ],
    name: {
      label: "Confidentiality",
      numOfName: 1,
    },
  },
  {
    id: 1,
    status: 1,
    text: [
      `The rights provided in this [Agreement] may not be assigned or transferred by [Investor] without [Manager's] written consent. This [Agreement] is for the sole benefit of the parties hereto and their respective successors and permitted assigns, and nothing herein, express or implied, is intended to or will confer upon any other person or entity any legal or equitable right, benefit or remedy of any nature whatsoever under or by reason of this [Agreement].`,
    ],
    name: {
      label: "Assignment",
      numOfName: 2,
    },
  },
  {
    id: 1,
    status: 2,
    text: [
      `This [Agreement] may be executed in counterparts, each of which will be deemed an original, but all of which together will be deemed to be one and the same agreement. Counterparts may be delivered via facsimile, electronic mail (including PDF or any electronic signature complying with the U.S. federal ESIGN Act of 2000, e.g., [www.docusign.com]) or other transmission method, and any counterpart so delivered will be deemed to have been duly and validly delivered and be valid and effective for all purposes.`,
    ],
    name: {
      label: "Counterparts",
      numOfName: 4,
    },
  },
  {
    id: 1,
    status: 1,
    text: [
      "The Investment Manager will notify the Investor within [X] days of the occurrence of any of the following events: (a)The death or permanent disability (a disease or injury rendering one materially unable to perform one's duties for a period of at least 60 consecutive days) of [KEY PERSON NAMES] (each, a 'Key Person'). (b) Any Key Person ceases to be actively involved with respect to the investment decisions of the Partnership and no longer devotes substantial business time to the management and control of the affairs of the Investment Manager. (c) Any Key Person encounters any legal issues that have or may have a material adverse impact on the Partnership, including but not limited to if any Key Person has either pled guilty to or been convicted of a felony or a breach of any applicable securities laws. The Investor will have the right upon receipt of such notice to request a redemption of all or any portion of its investment in the Partnership without incurring any withdrawal penalty set out in [RELEVANT SECTION] of the Partnership Documents. The effective date of any such redemption shall be not later than the end of the calendar month following the month in which the relevant event or circumstance described in clauses (a) through (c) above occurs.]",
    ],
    name: {
      label: "Key Person",
      numOfName: 2,
    },
  },
  {
    id: 1,
    status: 3,
    text: [
      "The Investment Manager shall promptly notify the Investor upon the occurrence of [list of Key Person Events]. Upon receipt of such notice the Investor may withdraw all or any portion of its investment upon not less than four months’ prior written notice; provided that the Investor has, prior to providing such withdrawal notice, granted the Investment Manager a reasonable opportunity to make a presentation regarding its capabilities to continue to manage the Partnership.",
    ],
    name: {
      label: "Key Person",
      numOfName: 4,
    },
  },
];
