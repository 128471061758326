export async function fetchGraphQL(operationsDoc, operationName, variables) {
  const result = await fetch("https://bibliotek.hasura.app/v1/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-hasura-admin-secret":
        "d4EIXj9cK4ILsqJZTyrt0IeUqNHbR6Z6TVPWYZXqLk2qa1JPFgPP3ECKj9yIqkAN",
    },
    body: JSON.stringify({
      query: operationsDoc,
      variables: variables,
      operationName: operationName,
    }),
  });
  return await result.json();
}
